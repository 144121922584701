<template>
	<div>
		<v-data-table 
			:headers="headers" 
			:items="bpbanks.data" 
			sort-by="bp_code" 
			class="elevation-1" 
			dense
			:search="search"
			hide-default-footer
			:items-per-page="1000"
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>Business Partners Bank File Maintenance</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>        
					<vue-excel-xlsx
						:data="bpbanks.data"
						:columns="columnHeaders"
						:file-name="module_name"
						>
						<v-btn icon alt class="mt-2" :color="bpbanks.data.length=== 0 ? 'gray' : 'primary'" :disabled="bpbanks.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
					</vue-excel-xlsx>
				</v-toolbar>
			</template>
    <template v-slot:item.action="{ item }">
      <!-- <v-icon
        color="primary"
        small
        class="mr-2"
        @click="editItem(item)"
      >
        edit
      </v-icon> -->
		<v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 4"
		>
			mdi-delete-off
		</v-icon>
      <v-icon
        color="red"
		v-else
        small
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(bpbanks.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(bpbanks.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{bpbanks.current_page}} ( {{bpbanks.from}} - {{bpbanks.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!bpbanks.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!bpbanks.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
  </v-data-table>
  <!-- <create-bpbank></create-bpbank> -->
  </div>
</template>

<script>
// import createBpbank from './createBpbank.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		// 'create-bpbank': createBpbank
	},
	data(){
		return{
			module_name: 'BP Banks',
			role_access: 1,
			search: ''
		};
	},
	computed: {
		...mapGetters({
			bpbanks: 'bpbank/bpbanks',
			headers: 'bpbank/headers',
			columnHeaders: 'bpbank/columnHeaders',
			editedItem: 'bpbank/editedItem',
			defaultItem: 'bpbank/defaultItem',
			dialog: 'bpbank/dialog',
			editedIndex: 'bpbank/editedIndex',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('bpbank/getBpbanks');
		this.$store.dispatch('businesspartner/getBusinesspartners');
		this.$store.dispatch('bankheader/getBankheaders');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('bpbank/setEditedIndex',this.bpbanks.data.indexOf(item));
			this.$store.dispatch('bpbank/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('bpbank/setDialog',true);
			this.$store.dispatch('bpbank/setValid',true);
			this.$store.dispatch('bpbank/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.account_number + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('bpbank/setEditedIndex', this.bpbanks.data.indexOf(item));
					this.$store.dispatch('bpbank/deleteBpbank',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.bpbanks.current_page + 1;
			this.$store.dispatch('bpbank/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.bpbanks.current_page - 1;
			this.$store.dispatch('bpbank/getDataPages', prevPage);
		}
	}
};
</script>