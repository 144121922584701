<template>
	<div class="home">
		<table-bpbank></table-bpbank>
  </div>
</template>

<script>
import tableBpbank from '@/components/pages/maint/bpbank/tableBpbank.vue';

export default {
	components: {
		'table-bpbank': tableBpbank,
	}
};
</script>

<style>

</style>
